import React, { useState } from "react";
import axios from "axios";
import { FiLoader } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function HomePage() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [holdConnect, setHoldConnect] = useState("");
  const [task, setTask] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null); // 'success' or 'failure'

  function formatPhoneNumber(input) {
    // Only allow numbers
    const sanitized = input.replace(/\D/g, "");

    if (sanitized.length < 4) {
      return sanitized;
    }

    if (sanitized.length < 7) {
      return `${sanitized.substring(0, 3)}-${sanitized.substring(3)}`;
    }

    return `${sanitized.substring(0, 3)}-${sanitized.substring(
      3,
      6
    )}-${sanitized.substring(6, 10)}`;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const formattedHoldConnect = formatPhoneNumber(holdConnect);

    if (!formattedPhoneNumber || !formattedHoldConnect) {
      // handle invalid phone number formatting
      setStatus("failure");
      return;
    }

    // ... Continue with the Axios request using the formatted phone numbers

    const data = {
      phone_number: phoneNumber,
      hold_connect: holdConnect,
      task: task,
    };

    try {
      const response = await axios.post("/api/call", data); // Replace with your Vercel function URL if hosted elsewhere
      if (response.data) {
        setStatus("success");
      } else {
        setStatus("failure");
      }
    } catch (error) {
      setStatus("failure");
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <FiLoader className="animate-spin text-primary" size={50} />
      </div>
    );
  }

  if (status === "success") {
    return (
      <div className="h-screen flex flex-col justify-center items-center max-w-xl mx-auto">
        <h1 className="text-3xl font-bold text-center tracking-tight text-black mb-2">
          Success!
        </h1>
        <p className="text-secondary text-center">
          Your AI agent is currently on hold. Once a human answers, they'll call
          you. In the meantime, would you like to send another call?
        </p>

        <button
          onClick={() => {
            setStatus(null);
          }}
          className="bg-dark text-white px-4 py-2 rounded border border-black shadow-sm transition-all hover:bg-black hover:shadow-lg mt-4"
        >
          Send another call
        </button>
      </div>
    );
  }

  if (status === "failure") {
    return (
      <div className="h-screen flex flex-col justify-center items-center">
        <h1 className="text-2xl text-red font-bold">Failure</h1>
        <p>There was a problem initiating the AI Call.</p>
      </div>
    );
  }

  const handlePhoneInputChange = (e, setterFunc) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    setterFunc(formattedValue);
  };

  function PoweredByFooter() {
    return (
      <a
        href="https://bland.ai"
        className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white px-4 py-1 rounded shadow-sm border border-primary transition-all hover:bg-primary hover:text-white hover:shadow-lg hover:scale-110"
      >
        Built with Bland
      </a>
    );
  }

  return (
    <div className="bg-white h-screen">
      <div className="max-w-xl mx-auto py-12 px-8 sm:px-6 lg:py-16 lg:px-8 py-16">
        <h1 className="text-3xl font-bold text-center tracking-tight text-black">
          Hold For Me
        </h1>

        <p className="text-center mt-2 text-secondary">
          An AI assistant that makes calls, waits on hold, and calls you when a
          human answers.
        </p>

        <div className="mt-8 bg-gray hover:shadow transition-all rounded-lg p-8">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="mt-4 mb-1 text-sm font-medium">
                Destination Phone Number (USA only)
              </label>
              <p className="text-sm text-secondary mb-2">
                Who do you want the AI to call? E.g. your doctor, insurance,
                bank.
              </p>

              <PhoneInput
                country="us"
                regions={["north-america", "carribean"]}
                disableDropdown
                value={phoneNumber}
                onChange={(value, country, e, formattedValue) =>
                  setPhoneNumber(formattedValue)
                }
                inputProps={{
                  required: true,
                }}
                inputStyle={{ width: "100%" }}
              />

              {/* <input
                type="text"
                value={phoneNumber}
                onChange={(e) => handlePhoneInputChange(e, setPhoneNumber)}
                placeholder="111-222-3344"
                className="text-left bg-white text-black border border-accent w-full px-4 py-2 rounded placeholder-secondary focus:outline-none focus:border-dark"
                required
              /> */}

              {/* <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="tel"
                  placeholder="888-888-8888"
                  value={phoneNumber}
                  maxLength="12"
                  onChange={(e) => handlePhoneInputChange(e, setPhoneNumber)}
                  className="block w-full pr-10 border-gray-300 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm rounded-md"
                  required
                />

                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">US Phone</span>
                </div>
              </div> */}
            </div>

            <label className="mt-4 mb-1 text-sm font-medium">
              Your phone number (USA only)
            </label>
            <p className="text-sm text-secondary mb-2">
              The AI will call you when a human representative answers. Remember
              to turn off Do Not Disturb.
            </p>

            <PhoneInput
              country="us"
              regions={["north-america", "carribean"]}
              disableDropdown
              value={holdConnect}
              onChange={(value, country, e, formattedValue) =>
                setHoldConnect(formattedValue)
              }
              inputStyle={{ width: "100%" }}
              inputProps={{
                required: true,
              }}
            />
            {/* <input
              type="tel"
              placeholder="555-666-7789"
              value={holdConnect}
              maxLength="12" // xxx-xxx-xxxx format, hence 12 characters
              onChange={(e) => handlePhoneInputChange(e, setHoldConnect)}
              className="text-left bg-white text-black border border-accent w-full px-4 py-2 rounded placeholder-secondary focus:outline-none focus:border-dark mb-4"
              required
            /> */}

            <div className="mt-4">
              <label className="mt-8 mb-1 text-sm font-medium">
                Task (optional)
              </label>
            </div>

            <p className="text-sm text-secondary mb-2">
              Give the AI information about the purpose of your call. The AI
              needs to know the reason for the phone call to navigate the phone
              tree and get transferred to the right representative to answer.
            </p>
            <textarea
              placeholder="Describe the task"
              value={task}
              rows={4}
              maxLength={400}
              onChange={(e) => setTask(e.target.value)}
              className="text-left bg-white text-black border border-accent w-full px-4 py-2 rounded placeholder-secondary focus:outline-none focus:border-dark mb-4"
            />

            <div className="flex items-center justify-between mt-4">
              <button className="bg-primary text-white px-4 py-2 w-full rounded shadow-sm transition-all hover:bg-primary/90 hover:shadow-lg flex justify-center items-center">
                <svg
                  fill="none"
                  viewBox="0 0 24 24"
                  height="16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M3.83301 4H8.32295L9.77016 7.61803L7.44528 9.16795C7.16708 9.35342 6.99998 9.66565 6.99998 10C7.00252 10.0936 6.99998 10.001 6.99998 10.001L6.99998 10.002L6.99999 10.0043L7.00002 10.0093L7.00017 10.0217C7.00032 10.0309 7.00057 10.0421 7.00099 10.0552C7.00184 10.0814 7.00337 10.1153 7.0061 10.1564C7.01158 10.2385 7.02189 10.3494 7.04128 10.4852C7.08004 10.7565 7.15526 11.1281 7.3013 11.5662C7.59453 12.4459 8.17071 13.5849 9.29288 14.7071C10.415 15.8293 11.554 16.4054 12.4338 16.6987C12.8719 16.8447 13.2435 16.9199 13.5148 16.9587C13.6505 16.9781 13.7615 16.9884 13.8436 16.9939C13.8847 16.9966 13.9186 16.9981 13.9448 16.999C13.9579 16.9994 13.9691 16.9997 13.9783 16.9998L13.9906 17L13.9957 17L13.9979 17L13.999 17C13.999 17 14.1106 16.9939 14 17C14.3788 17 14.725 16.786 14.8944 16.4472L15.5641 15.1078L20 15.8471V20.167C17.8889 20.4723 12.1874 20.7732 7.70709 16.2929C3.22679 11.8126 3.5277 6.1111 3.83301 4ZM9.07363 10.4861L10.8796 9.28213C11.6665 8.75751 11.9784 7.75338 11.6271 6.87525L10.1799 3.25722C9.87618 2.4979 9.14076 2 8.32295 2H3.78076C2.87225 2 2.01707 2.63116 1.86792 3.6169C1.52861 5.8595 1.06677 12.481 6.29288 17.7071C11.519 22.9332 18.1405 22.4714 20.3831 22.1321C21.3688 21.9829 22 21.1277 22 20.2192V15.8471C22 14.8694 21.2932 14.0351 20.3288 13.8743L15.8929 13.135C15.0268 12.9907 14.1679 13.4281 13.7753 14.2134L13.4288 14.9064C13.3199 14.8796 13.1983 14.8453 13.0662 14.8013C12.4459 14.5946 11.5849 14.1707 10.7071 13.2929C9.82925 12.4151 9.40543 11.5541 9.19867 10.9338C9.14279 10.7662 9.10257 10.6154 9.07363 10.4861Z"
                    fill="white"
                  ></path>
                </svg>
                Initiate call
              </button>
            </div>
          </form>
        </div>
      </div>
      <PoweredByFooter />
    </div>
  );
}

export default HomePage;
